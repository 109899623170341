import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getEnumsFromState } from 'common/utils/Enums';
import updateCurrentEmployeeState from 'src/api/core/Employees/updateCurrentEmployeeState';
import updateEmployee from 'src/actions/User/User/updateEmployee';
import { AppUserSettings } from '@unite-us/app-user-settings';
import _ from 'lodash';
import { hasUserRole, isOrgAdmin } from 'src/components/User/utils';
import {
  hasInvoicesAccess,
  hasPayerAuthorizationAccess,
  hasPayerEnrollmentsAccess,
  hasPaymentsUserAccess,
  isMessagingEnabledFlag,
  hasPayerInvoicesRole,
  isTasksMenuEnabled,
} from 'src/common/utils/FeatureFlags/flags';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';
import { isTaskNotification } from './utils';

function NotificationPreferencesForm(props) {
  const [submitting, setSubmitting] = useState(false);
  const {
    currentEmployee,
    enums,
    isCareManager,
    isPayerAuthorizationUser,
    showEnrollment,
    showInvoice,
    showMessaging,
    showPayerInvoices,
    showPayment,
    showTasks,
    hasOrgAdmin,
  } = props;

  async function submitForm(formData) {
    setSubmitting(true);
    let updatedGroupNotifications = {};
    if (isTaskNotification(formData)) {
      const updatedinAppNotifications = {
        ...currentEmployee?.notification_preferences?.in_app,
        ...formData,
      };
      updatedGroupNotifications = {
        ...currentEmployee?.notification_preferences,
        in_app: updatedinAppNotifications,
      };
    } else {
      updatedGroupNotifications = {
        ...currentEmployee.notification_preferences,
        ...formData,
      };
    }

    return props.updateEmployee(currentEmployee.id, {
      notification_preferences: updatedGroupNotifications,
    }).then((payload) => {
      props.updateCurrentEmployeeState(payload);
      setSubmitting(false);
    });
  }

  const labels = _.mapValues(props.labels, _.toLower);

  return (
    <AppUserSettings
      appState={{
        currentEmployee,
        source: 'app-client',
        submitting,
        enums: {
          ...enums,
          labels,
        },
      }}
      callbacks={{
        submitForm,
      }}
      flags={{
        showMessaging,
        showPayerInvoices,
        showEnrollment,
        showPayment,
        hasOrgAdmin,
        isPayerAuthorizationUser,
        isCareManager,
        showInvoice,
        showTasks,
      }}
    />
  );
}

NotificationPreferencesForm.propTypes = {
  currentEmployee: PropTypes.object.isRequired,
  enums: PropTypes.shape({
    preferences: PropTypes.shape({
      binary: PropTypes.array,
    }).isRequired,
  }).isRequired,
  hasOrgAdmin: PropTypes.bool.isRequired,
  isCareManager: PropTypes.bool.isRequired,
  isPayerAuthorizationUser: PropTypes.bool.isRequired,
  showEnrollment: PropTypes.bool.isRequired,
  showInvoice: PropTypes.bool.isRequired,
  showMessaging: PropTypes.bool.isRequired,
  showPayerInvoices: PropTypes.bool.isRequired,
  showPayment: PropTypes.bool.isRequired,
  showTasks: PropTypes.bool.isRequired,
  updateCurrentEmployeeState: PropTypes.func.isRequired,
  updateEmployee: PropTypes.func.isRequired,
  labels: PropTypes.object,
};

NotificationPreferencesForm.defaultProps = {
  labels: defaultLabels,
};

function mapStateToProps(state) {
  const currentEmployee = _.get(state, 'globalState.currentEmployee', {});
  const hasFeeSchedules = currentEmployee.fee_schedules.length !== 0;
  const isNotPayer = currentEmployee.provider.provider_type !== 'payer';
  return {
    currentEmployee,
    enums: getEnumsFromState(state),
    isCareManager: hasPaymentsUserAccess(state) && hasFeeSchedules && isNotPayer,
    isPayerAuthorizationUser: hasPayerAuthorizationAccess(state),
    showInvoice: hasInvoicesAccess(state),
    showEnrollment: hasPayerEnrollmentsAccess(state),
    showPayment: hasPaymentsUserAccess(state),
    showMessaging: isMessagingEnabledFlag(state),
    showPayerInvoices: hasPayerInvoicesRole(state),
    showTasks: hasUserRole({
      groupId: state.session.groupId,
      name: 'Tasks',
      roleType: 'feature',
      user: state.user,
    }) && isTasksMenuEnabled(state),
    hasOrgAdmin: isOrgAdmin(state.user, state.session.groupId),
    labels: labelCustomization(state),
  };
}

export default connect(mapStateToProps, {
  updateCurrentEmployeeState,
  updateEmployee,
})(NotificationPreferencesForm);
