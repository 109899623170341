import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@unite-us/ui';
import validations from '@unite-us/client-utils/dist/utils/validations';

const ProcedureCodeRow = (props) => {
  const {
    row,
    options,
    index,
    registerField,
    feeScheduleProgram,
  } = props;
  const forceProcedureCodes = feeScheduleProgram.force_procedure_code_associations;
  const forceModifiers = feeScheduleProgram.force_procedure_code_modifier_associations;

  const pcmOptions = options
    .filter((option) => (option.value === row.procedure_code.value))
    .map((option) => (option.procedure_code_modifiers)).flat();

  const onProcedureCodeChange = () => {
    if (forceModifiers) {
      row.procedure_code_modifiers.onChange(pcmOptions.map((pcm) => (pcm.value)));
    } else {
      row.procedure_code_modifiers.onChange([]);
    }
  };

  const hideSelects = !forceProcedureCodes && !forceModifiers && options.length === 1 && pcmOptions.length === 1;

  return (
    <div className={'flex flex-row items-center my-0 px-0'} key={index}>
      <SelectField
        id={`provided-service-procedure-code-rows-${index}-procedure-code`}
        field={row?.procedure_code}
        options={options}
        placeholder="Choose one..."
        label="Procedure Code"
        searchPlaceholderValue="Search by code or description"
        onChange={onProcedureCodeChange}
        autoSelectValue={forceProcedureCodes ? false : options.length === 1}
        className={hideSelects ? 'hidden' : 'my-1 pr-2'}
        ref={registerField}
        validations={(val) => validations.isRequired(val)}
        required
        disabled={forceProcedureCodes}
      />
      <SelectField
        id={`provided-service-procedure-code-rows-${index}-procedure-code-modifiers`}
        field={row?.procedure_code_modifiers}
        label="Procedure Code Modifier"
        searchPlaceholderValue="Search by description"
        autoSelectValue={pcmOptions.length === 1 || forceModifiers}
        options={pcmOptions}
        placeholder="Select modifiers..."
        className={hideSelects ? 'hidden' : 'my-1'}
        required
        ref={registerField}
        validations={(val) => validations.isRequired(val)}
        disabled={pcmOptions.length === 1 || forceModifiers}
        multiple={forceModifiers}
      />
    </div>
  );
};

ProcedureCodeRow.propTypes = {
  index: PropTypes.number.isRequired,
  feeScheduleProgram: PropTypes.object.isRequired,
  options: PropTypes.array,
  registerField: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

ProcedureCodeRow.defaultProps = {
  options: [],
};

export default ProcedureCodeRow;
