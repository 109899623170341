import { useFind } from 'src/api/APIHooks';
import { isEmpty, get } from 'lodash';

const useZcodes = (feeScheduleProgramIds, payerId, params = {}) => {
  const { queryConfig = {} } = params;
  const fspQuery = !isEmpty(feeScheduleProgramIds) ? { fee_schedule_program: feeScheduleProgramIds.join() } : null;
  const payerQeury = payerId ? { payer: payerId } : null;

  const { data: response } = useFind(
    'zcode',
    {
      ...fspQuery, ...payerQeury,
    },
    {
      queryConfig: {
        placeholderData: undefined,
        enabled: !isEmpty(feeScheduleProgramIds) && !!payerId,
        ...queryConfig,
      },
    },
  );

  const data = get(response, 'data.data', []);
  return { data };
};

export default useZcodes;
