import {
  UPDATE_PAYMENTS_PROVIDED_SERVICE_ERROR,
  UPDATE_PAYMENTS_PROVIDED_SERVICE_SUCCESS,
  UPDATE_PAYMENTS_PROVIDED_SERVICE_STARTED,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import _ from 'lodash';
import { isHttpError } from 'common/utils/Error';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { apiV4 } from 'src/api/config';

const buildService = ({
  providedService, caseId, programId, planId,
}) => ({
  actual_unit_amount: providedService.actual_unit_amount ? providedService.actual_unit_amount : null,
  unit_amount: providedService.unit_amount ? providedService.unit_amount : null,
  starts_at: providedService.service_start_date ?
    new Date(providedService.service_start_date * 1000).toISOString() :
    null,
  state: providedService.state,
  ...(providedService.distribution_reason && { distribution_reason: providedService.distribution_reason }),
  ...(providedService.payment_method && { payment_method: providedService.payment_method }),
  ...(providedService.metadata && { metadata: providedService.metadata }),
  case: caseId,
  program: programId,
  ...(planId && { plan: planId }),
  // allow optional fields to be nullable
  ends_at: providedService.service_end_date ? new Date(providedService.service_end_date * 1000).toISOString() : null,
  distribution_id: providedService.distribution_id,
  payee: providedService.payee,
  notes: providedService.note,
  zcodes: providedService.zcodes,
  place_of_service: providedService.place_of_service,
  procedure_codes: !_.isEmpty(providedService.procedure_codes) ?
    providedService.procedure_codes : undefined,
  procedure_code_modifiers: !_.isEmpty(providedService.procedure_code_modifiers) ?
    providedService.procedure_code_modifiers : undefined,
});

function updateFeeScheduleProvidedService({
  caseId,
  contactId,
  programId,
  providedService,
  groupId,
  showNotification = true,
  id,
  planId,
}) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PAYMENTS_PROVIDED_SERVICE_STARTED });

    const service = buildService({
      providedService, caseId, programId, planId,
    });
    const payload = await apiV4.updateRecord('provided_service', id, service, { groupId });

    if (showNotification && (!payload || !isHttpSuccess(payload.status))) {
      dispatch({ type: UPDATE_PAYMENTS_PROVIDED_SERVICE_ERROR });
      if (isHttpError(payload)) {
        const errorMessage = service.state === 'active' ?
          'We\'ve encountered an unexpected problem while saving the contracted service. Please try again.' :
          'We\'ve encountered an unexpected problem submitting the contracted service. Please try again.';

        Notifier.dispatch('error', errorMessage);
      } else {
        Notifier.handleErrors(payload, dispatch);
      }
      return false;
    }

    dispatch({
      type: UPDATE_PAYMENTS_PROVIDED_SERVICE_SUCCESS,
      contactId,
      caseId,
      payload,
    });

    /*
      * We notify the user of the successful provided service submission / draft here instead of
      * createFeeScheduleProvidedService because the files have now been created at this point.
    */
    if (showNotification) {
      const successMessage = service.state === 'active' ?
        'Your contracted service has been saved' :
        'Your submission request is in queue and processing. View the progress in Invoices.';

      Notifier.dispatch(payload.status, successMessage);
    }

    return payload;
  };
}

export default updateFeeScheduleProvidedService;
