import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { fetchFeeSchedules as fetchFS } from 'actions/FeeSchedules';
import { useFindRecord, usePopulate } from 'api/APIHooks';
import { fetchPaymentsInsurance as fetchIsurances } from 'src/components/Insurance/actions';
import { fetchInsurancePlanOptions } from 'src/components/Insurance/utils';
import { getInsuranceExternalId } from 'src/components/Cases/utils';
import useZcodes from 'src/common/hooks/useZcodes';
import useInvoiceSpend from 'src/components/ServiceAuthorization/useInvoiceSpend';

export const PaymentsTrackServiceWrapper = (props) => {
  const {
    children,
    contactId,
    feeSchedule,
    feeScheduleId,
    feeScheduleProgramId,
    fetchFeeSchedules,
    fetchPaymentsInsurance,
    groupId,
    insurances,
    providedService,
    serviceAuthorizationId,
    serviceCase,
  } = props;

  const [fsPlans, setFSPlans] = useState([]);
  const [insurancePlans, setInsurancePlans] = useState([]);
  const [socialInsurances, setSocialInsurances] = useState([]);

  const { data: serviceAuthorizationData } = useFindRecord(
    'service_authorization',
    serviceAuthorizationId,
    { queryConfig: { placeholderData: undefined, enabled: !!serviceAuthorizationId } },
  );
  const serviceAuthorization = serviceAuthorizationData?.data?.data;
  usePopulate('insurance', 'insurance', serviceAuthorization, { queryConfig: { placeholderData: undefined } });
  usePopulate('insurance.plan', 'plan', serviceAuthorization, { queryConfig: { placeholderData: undefined } });
  const serviceAuthorizationPlanId = serviceAuthorization?.insurance?.plan?.id;
  const serviceAuthorizationPayerId = serviceAuthorization?.insurance?.plan?.payer?.id;

  const [inferredPlanId, setInferredPlanId] = useState(null);
  const { data: inferredPlanData } = useFindRecord(
    'plan',
    inferredPlanId,
    { queryConfig: { enabled: !serviceAuthorizationId && !!inferredPlanId, placeholderData: undefined } },
  );
  const inferredPayerId = inferredPlanData?.data?.data?.payer?.id;

  const canonicalPlanId = serviceAuthorizationPlanId || inferredPlanId;

  const casePayerId = serviceAuthorizationPayerId || inferredPayerId;

  const serviceAuthorizationInvoiceInformation = useInvoiceSpend(
    get(serviceCase, 'service_authorization.id'),
  );

  useEffect(() => {
    fetchFeeSchedules({ id: feeScheduleId });
  }, [feeScheduleId]);

  useEffect(() => {
    if (!isEmpty(feeSchedule)) {
      fetchPaymentsInsurance(contactId, groupId);
      fetchInsurancePlanOptions({
        planIds: get(feeSchedule, 'plans', []).map((plan) => (plan.id)),
        planType: 'social',
        showEnrollmentRequired: true,
      }).then((response) => {
        setFSPlans(response.data.data);
      });
    }
  }, [feeSchedule]);

  useEffect(() => {
    if (!isEmpty(feeSchedule) && feeSchedule.insurance_required && !isEmpty(insurances)) {
      const planIds = insurances.map((insurance) => (
        get(insurance, 'relationships.plan.data.id', '')
      ));
      fetchInsurancePlanOptions({
        planIds,
        showEnrollmentRequired: true,
      }).then((response) => {
        setInsurancePlans(response.data.data);
      });
    }
  }, [feeSchedule, insurances]);

  useEffect(() => {
    if (!isEmpty(insurances)) {
      const planIds = insurances.map((insurance) => (
        get(insurance, 'relationships.plan.data.id', '')
      ));
      fetchInsurancePlanOptions({
        planIds,
        planType: 'social',
        showEnrollmentRequired: true,
      }).then((response) => {
        const socialPlans = response.data.data;
        const plansMatchingFeeSchedule = socialPlans.filter((plan) => {
          const feeSchedules = get(plan, 'relationships.fee_schedules.data', []);
          return feeSchedules.map((fs) => fs.id).includes(feeSchedule.id);
        });
        const candidatePlanIds = plansMatchingFeeSchedule.map((plan) => plan.id);
        const candidateInsurances = insurances.filter((insurance) => (
          candidatePlanIds.includes(get(insurance, 'relationships.plan.data.id'))
        ));
        setSocialInsurances(candidateInsurances);
      });
    }
  }, [insurances]);

  const insuranceExternalMemberId = useCallback(
    getInsuranceExternalId({ insurances, fsPlans, insurancePlans }),
    [insurances, fsPlans, insurancePlans],
  );

  const { data } = useFindRecord(
    'fee_schedule_program',
    feeScheduleProgramId,
    { queryConfig: { placeholderData: undefined, enabled: !!feeScheduleProgramId } },
  );
  const feeScheduleProgram = data?.data?.data;

  usePopulate(
    'place_of_services',
    'place_of_services',
    feeScheduleProgram,
    { queryConfig: { placeholderData: undefined } },
  );

  const placeOfServiceOptions = feeScheduleProgram?.place_of_services
    ?.map((pos) => ({ label: `${pos.code} - ${pos.description}`, value: pos.id })) ?? [];

  const zcodeConfig = { queryConfig: { enabled: !feeScheduleProgram?.force_zcode_associations } };
  const { data: zcodes } = useZcodes([feeScheduleProgramId], casePayerId, zcodeConfig);
  const zcodeOptions = feeScheduleProgram?.force_zcode_associations ? [] :
    zcodes.map((zcode) => ({ label: `${zcode.code} - ${zcode.description}`, value: zcode.id }));

  usePopulate(
    'procedure_code_modifiers',
    'procedure_code_modifiers',
    providedService,
    { queryConfig: { placeholderData: undefined } },
  );

  return React.Children.map(children, (child) => (
    React.cloneElement(child, {
      casePayerId,
      feeScheduleProgram,
      insuranceExternalMemberId,
      insuranceRequired: feeSchedule.insurance_required,
      placeOfServiceOptions,
      planId: canonicalPlanId,
      providedService,
      socialInsurances,
      serviceAuthorizationInvoiceInformation,
      serviceAuthorization,
      setInferredPlanId,
      zcodeOptions,
    })
  ));
};

PaymentsTrackServiceWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  contactId: PropTypes.string.isRequired,
  feeSchedule: PropTypes.object,
  feeScheduleId: PropTypes.string.isRequired,
  feeScheduleProgramId: PropTypes.string.isRequired,
  fetchFeeSchedules: PropTypes.func.isRequired,
  fetchPaymentsInsurance: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  insurances: PropTypes.array,
  providedService: PropTypes.object,
  serviceCase: PropTypes.object,
  serviceAuthorizationId: PropTypes.string,
};

PaymentsTrackServiceWrapper.defaultProps = {
  feeSchedule: {},
  insurances: [],
  providedService: {},
  serviceCase: {},
  serviceAuthorizationId: '',
};

const mapStateToProps = (state, ownProps) => {
  const programId = get(ownProps.serviceCase, 'program.id');
  const groupId = state.session.groupId;
  const program = get(state, 'groupsPrograms.data').find(({ id }) => id === programId);
  const feeScheduleId = get(program, 'fee_schedule_program.relationships.fee_schedule.data.id', '');
  const feeSchedule = get(state, `feeSchedules.data.${feeScheduleId}`, {});
  const feeScheduleProgramId = get(program, 'fee_schedule_program.id', '');
  const insurances = get(state, `insurances.data.${ownProps.contactId}`, []);
  const serviceAuthorizationId = get(ownProps.serviceCase, 'service_authorization.id');

  return {
    feeScheduleId,
    feeSchedule,
    feeScheduleProgramId,
    insurances,
    groupId,
    serviceAuthorizationId,
  };
};

export default connect(mapStateToProps, {
  fetchFeeSchedules: fetchFS,
  fetchPaymentsInsurance: fetchIsurances,
})(PaymentsTrackServiceWrapper);
