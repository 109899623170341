import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, noop, flow } from 'lodash';
import { TrackerContext } from '@unite-us/client-utils';
import { BaseCard, BaseCardBody } from '@unite-us/ui';
import { PaymentsTrackServiceOld, PaymentsTrackServiceWrapper } from 'src/common/form/Interactions/components';
import { PaymentsTrackService } from 'src/common/form/Interactions/components/PaymentsTrackService';
import callOrLog from 'common/utils/callOrLog';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { SERVICES_PROVIDED } from 'common/utils/EventTracker/utils/eventConstants';
import { UPDATE_FEE_SCHEDULE_PROVIDED_SERVICE } from 'src/components/Cases/constants';
import { useFindProviderRequired } from 'src/components/Organization/api/hooks/v1/providerHooks';
import {
  submitFeeScheduleProvidedService as submitFSPS,
} from 'actions/Case/Contact/Group';
import sortProvidedServicesByStartsAt from 'src/components/Cases/utils/sortProvidedServicesByStartsAt';
import { fetchFeeSchedules as fetchFS } from 'actions/FeeSchedules';
import { fetchPaymentsInsurance as fetchIsurances } from 'src/components/Insurance/actions';
import useFeatureFlag from 'src/common/hooks/useFeatureFlag';
import FeeScheduleProvidedServiceCardData from './FeeScheduleProvidedServiceCardData';
import FeeScheduleProvidedServiceCardHeader from './FeeScheduleProvidedServiceCardHeader';

const FeeScheduleProvidedServiceCards = ({
  capInformation,
  contactId,
  editMode,
  feeScheduleProvidedServices,
  groupId,
  isBillable,
  isCostType,
  isEditing,
  metafields,
  paymentType,
  programId,
  selectedProvidedServiceId,
  serviceCase,
  showServicesProvidedInteractions,
  unit,
  submitFeeScheduleProvidedService,
  feeScheduleId,
  fetchFeeSchedules,
  onDelete,
}) => {
  const eventTracker = useContext(TrackerContext);
  const edit = (providedServiceId) => () => {
    callOrLog(() => eventTracker(SERVICES_PROVIDED.editNote));
    editMode(providedServiceId);
  };

  const hasPaymentsTrackServiceRefactoring = useFeatureFlag('pays-3318-payments-track-service-refactoring');

  const { data } = useFindProviderRequired({ providerId: groupId });
  const isNetworkLead = get(data, 'provider_type') === 'network_lead';

  useEffect(() => {
    fetchFeeSchedules({ id: feeScheduleId });
  }, [feeScheduleId]);

  const submit = (providedService) => () => submitFeeScheduleProvidedService({
    providedService,
    id: providedService.id,
    caseId: serviceCase.id,
    contactId,
    groupId,
    programId,
  });

  return (
    <div className="space-y-5">
      {
        feeScheduleProvidedServices.length !== 0 &&
        sortProvidedServicesByStartsAt(feeScheduleProvidedServices).map((providedService) => (
          isEditing && (selectedProvidedServiceId === providedService.id) ? (
            <PaymentsTrackServiceWrapper
              key={providedService.id}
              contactId={contactId}
              serviceCase={serviceCase}
              providedService={providedService}
            >
              {hasPaymentsTrackServiceRefactoring ? (
                <PaymentsTrackService
                  isBillable={isBillable}
                  cancelFunction={() => editMode('')}
                  contactId={contactId}
                  form={`${UPDATE_FEE_SCHEDULE_PROVIDED_SERVICE}-${providedService.id}`}
                  serviceCase={serviceCase}
                  selectedProvidedServiceId={selectedProvidedServiceId}
                  providedService={providedService}
                  providedServiceId={providedService.id}
                  isCostType={isCostType}
                  isEditing={isEditing}
                />
              ) : (
                <PaymentsTrackServiceOld
                  isBillable={isBillable}
                  cancelFunction={() => editMode('')}
                  contactId={contactId}
                  formName={`${UPDATE_FEE_SCHEDULE_PROVIDED_SERVICE}-${providedService.id}`}
                  serviceCase={serviceCase}
                  selectedProvidedServiceId={selectedProvidedServiceId}
                  providedServiceId={providedService.id}
                  isCostType={isCostType}
                  isEditing={isEditing}
                />
              )}
            </PaymentsTrackServiceWrapper>
          ) : (
            <BaseCard key={providedService.id} className="fee-schedule-provided-service-card">
              <BaseCardBody>
                <div>
                  <FeeScheduleProvidedServiceCardHeader
                    className="py-4 px-8"
                    isBillable={isBillable}
                    isNetworkLead={isNetworkLead}
                    metafields={metafields}
                    onEdit={edit(providedService.id)}
                    onSubmit={submit(providedService)}
                    programName={get(serviceCase, 'program.name')}
                    providedService={providedService}
                    showInteractions={showServicesProvidedInteractions}
                    onDelete={onDelete}
                  />
                  <hr className="border-dark-fill-blue" />
                  <FeeScheduleProvidedServiceCardData
                    capInformation={capInformation}
                    className="py-4 px-8 box-border"
                    paymentType={paymentType}
                    programName={get(serviceCase, 'program.name')}
                    providedService={providedService}
                    unit={unit}
                    metafields={metafields}
                  />
                </div>
              </BaseCardBody>
            </BaseCard>
          )
        ))
      }
    </div>
  );
};

FeeScheduleProvidedServiceCards.propTypes = {
  capInformation: PropTypes.string,
  contactId: PropTypes.string,
  editMode: PropTypes.func,
  feeScheduleId: PropTypes.string,
  feeScheduleProvidedServices: PropTypes.array.isRequired,
  groupId: PropTypes.string,
  isBillable: PropTypes.bool,
  isEditing: PropTypes.bool,
  isCostType: PropTypes.bool.isRequired,
  paymentType: PropTypes.string.isRequired,
  programId: PropTypes.string,
  metafields: PropTypes.array,
  serviceCase: PropTypes.shape({
    id: PropTypes.string,
    contact: PropTypes.object,
  }).isRequired,
  selectedProvidedServiceId: PropTypes.string,
  submitFeeScheduleProvidedService: PropTypes.func.isRequired,
  showServicesProvidedInteractions: PropTypes.bool,
  unit: PropTypes.string,
  fetchFeeSchedules: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

FeeScheduleProvidedServiceCards.defaultProps = {
  capInformation: '',
  contactId: '',
  editMode: noop,
  groupId: '',
  isBillable: false,
  isEditing: false,
  metafields: [],
  programId: '',
  selectedProvidedServiceId: '',
  showServicesProvidedInteractions: true,
  unit: '',
  feeScheduleId: '',
};

const mapStateToProps = (state, ownProps) => {
  const groupId = state.session.groupId;
  const programId = get(ownProps.serviceCase, 'program.id');
  const program = get(state, 'groupsPrograms.data').find(({ id }) => id === programId);
  const isBillable = get(program, 'fee_schedule_program.attributes.billable');
  const feeScheduleId = get(program, 'fee_schedule_program.relationships.fee_schedule.data.id', '');
  const insurances = get(state, `insurances.data.${ownProps.contactId}`, []);

  return {
    groupId,
    isBillable,
    programId,
    feeScheduleId,
    insurances,
  };
};

export default flow(
  FeatureFlagContainer,
  connect(mapStateToProps, {
  submitFeeScheduleProvidedService: submitFSPS,
  fetchFeeSchedules: fetchFS,
  fetchPaymentsInsurance: fetchIsurances,
}),
)(FeeScheduleProvidedServiceCards);
