import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { feeSchedulePrograms as fspUtils, TrackerContext } from '@unite-us/client-utils';
import AddCirclePlusButton from 'common/display/Profile/components/AddCirclePlusButton';
import { PaymentsTrackServiceOld, PaymentsTrackServiceWrapper } from 'src/common/form/Interactions/components';
import { PaymentsTrackService } from 'src/common/form/Interactions/components/PaymentsTrackService';
import callOrLog from 'common/utils/callOrLog';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { SERVICES_PROVIDED } from 'common/utils/EventTracker/utils/eventConstants';
import { CREATE_FEE_SCHEDULE_PROVIDED_SERVICE } from 'src/components/Cases/constants';
import hideInteractions from 'src/components/Facesheet/Cases/utils/hideElement';
import { isEmpty, get, findIndex, flow } from 'lodash';
import { isCaseOpen } from 'src/components/Cases/utils';
import { fetchProvidedServices } from 'actions/Case/Contact/Group';
import { fetchGroupsPrograms } from 'actions/Program/Group';
import NoFeeScheduleProvidedServiceCard from 'src/components/Cases/components/Detail/NoFeeScheduleProvidedServiceCard';
import FeeScheduleProvidedServiceCards from 'src/components/Cases/components/Detail/FeeScheduleProvidedServiceCards';
import { Spinner } from 'common/spinners';
import { useFeatureFlag } from 'src/common/hooks';

function ServiceCaseFeeScheduleServicesProvided({
  caseId,
  className,
  contactId,
  editProvidedServiceId,
  editing,
  feeScheduleProvidedServices,
  fetchGroupsPrograms: fetchGroupsProgramsAction,
  fetchProvidedServices: fetchProvidedServiceAction,
  groupId,
  isCoordinationGroup,
  isCostType,
  paymentType,
  program,
  programId,
  scrollInto,
  serviceAuthorization,
  serviceCase,
  serviceCaseGroupId,
}) {
  const [isFeeScheduleClicked, setClick] = useState(false);
  const [isEditing, setEditMode] = useState(editing);
  const [scroll, setScroll] = useState(scrollInto);
  const [editedProvidedServiceId, setProvidedServiceEditId] = useState(editProvidedServiceId);
  const hasPaymentsTrackServiceRefactoring = useFeatureFlag('pays-3318-payments-track-service-refactoring');
  const pays5604AuthorizationForInternalCases = useFeatureFlag('pays-5604-authorization-for-internal-cases');
  const metafields = get(serviceCase, 'program.fee_schedule_program.metafields', []);
  const authStatus = serviceAuthorization.state;
  const hasNonApprovedAuth = pays5604AuthorizationForInternalCases && !!authStatus && (authStatus !== 'approved');

  const canAddServices = isCaseOpen(serviceCase);
  const eventTracker = useContext(TrackerContext);
  const showServicesProvidedInteractions = !hideInteractions({ isCoordinationGroup, serviceCase, groupId });
  const [isFetching, setFetchingState] = useState(true);

  const createAddFeeScheduleServicesHandler = useCallback(() => {
    if (!isFeeScheduleClicked) {
      callOrLog(() => eventTracker(SERVICES_PROVIDED.addedNewFeeScheduleServiceProvided));
    }
    setClick(!isFeeScheduleClicked);
  }, [isFeeScheduleClicked]);

  const updateProvidedServicesHandler = useCallback((providedServiceIdSelected) => {
    setEditMode(providedServiceIdSelected !== '');
    setProvidedServiceEditId(providedServiceIdSelected);
  }, []);

  const fetchPrograms = () => {
    async function fetchPS() {
      const returned = await fetchProvidedServiceAction({ caseId, groupId, contactId });
      if (isEditing && scroll && returned.status === 200) {
        const element = document.getElementsByClassName('payments-track-service-card--edit')[0];
        if (element !== undefined) {
          element.scrollIntoView();
          setScroll(false);
        }
      }
      setFetchingState(false);
    }
    fetchPS();
    if (isEmpty(paymentType)) {
      fetchGroupsProgramsAction(serviceCaseGroupId, {
        ids: programId,
        include: 'fee_schedule_program',
      });
    }
  };

  useEffect(
    () => {
      fetchPrograms();
    },
    [fetchGroupsPrograms, fetchProvidedServices, isEditing,
      editedProvidedServiceId, setEditMode, setProvidedServiceEditId],
  );

  const authToolTip =
    'You can only add a Contracted Service to the case if the authorization request is accepted.';

  return (
    <div className={className}>
      <div className="flex justify-between items-center mb-4">
        <h2>Contracted Service</h2>
        <div>
          {
            canAddServices && showServicesProvidedInteractions ? (
              <AddCirclePlusButton
                addHandler={createAddFeeScheduleServicesHandler}
                id="add-fee-schedule-service-provided-button"
                labelText="Add New Contracted Service"
                disabled={isFeeScheduleClicked || isEditing || hasNonApprovedAuth}
                toolTip={hasNonApprovedAuth ? authToolTip : ''}
              />
            ) : null
          }
        </div>
      </div>
      {
        !isFeeScheduleClicked && isFetching && (<Spinner />)
      }
      {
        !isFeeScheduleClicked && !isFetching && (
          (isEmpty(feeScheduleProvidedServices) ? (<NoFeeScheduleProvidedServiceCard />) :
            (
              <FeeScheduleProvidedServiceCards
                capInformation={get(program, 'fee_schedule_program.attributes.cap_information', '')}
                contactId={contactId}
                feeScheduleProvidedServices={feeScheduleProvidedServices}
                editMode={updateProvidedServicesHandler}
                isEditing={isEditing}
                paymentType={paymentType}
                isCostType={isCostType}
                metafields={metafields}
                serviceCase={serviceCase}
                unit={get(program, 'fee_schedule_program.attributes.unit', '')}
                selectedProvidedServiceId={editedProvidedServiceId}
                showServicesProvidedInteractions={showServicesProvidedInteractions}
                onDelete={() => {
                  setFetchingState(true);
                  fetchPrograms();
                }}
              />
            )))
      }
      {
        isFeeScheduleClicked && (
          <PaymentsTrackServiceWrapper
            contactId={contactId}
            serviceCase={serviceCase}
          >
            {hasPaymentsTrackServiceRefactoring ? (
              <PaymentsTrackService
                isBillable={get(program, 'fee_schedule_program.attributes.billable')}
                cancelFunction={() => setClick(!isFeeScheduleClicked)}
                contactId={contactId}
                isCostType={isCostType}
                serviceCase={serviceCase}
                form={CREATE_FEE_SCHEDULE_PROVIDED_SERVICE}
                paymentType={paymentType}
              />
            ) : (
              <PaymentsTrackServiceOld
                isBillable={get(program, 'fee_schedule_program.attributes.billable')}
                cancelFunction={() => setClick(!isFeeScheduleClicked)}
                contactId={contactId}
                isCostType={isCostType}
                serviceCase={serviceCase}
                formName={CREATE_FEE_SCHEDULE_PROVIDED_SERVICE}
                paymentType={paymentType}
              />
            )}
          </PaymentsTrackServiceWrapper>
        )
      }
    </div>
  );
}

ServiceCaseFeeScheduleServicesProvided.propTypes = {
  caseId: PropTypes.string,
  className: PropTypes.string,
  contactId: PropTypes.string,
  editProvidedServiceId: PropTypes.string,
  editing: PropTypes.bool,
  feeScheduleProvidedServices: PropTypes.array,
  fetchGroupsPrograms: PropTypes.func.isRequired,
  fetchProvidedServices: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  isCoordinationGroup: PropTypes.bool.isRequired,
  isCostType: PropTypes.bool.isRequired,
  paymentType: PropTypes.string,
  program: PropTypes.object,
  programId: PropTypes.string.isRequired,
  scrollInto: PropTypes.bool,
  serviceAuthorization: PropTypes.object.isRequired,
  serviceCase: PropTypes.shape({
    id: PropTypes.string,
    contact: PropTypes.object,
  }).isRequired,
  serviceCaseGroupId: PropTypes.string,
};

ServiceCaseFeeScheduleServicesProvided.defaultProps = {
  caseId: '',
  className: '',
  contactId: '',
  editProvidedServiceId: '',
  editing: false,
  feeScheduleProvidedServices: [],
  groupId: '',
  paymentType: '',
  program: '',
  scrollInto: false,
  serviceCaseGroupId: '',
};

function mapStateToProps(state, ownProps) {
  const { contactId, serviceCase } = ownProps;
  const serviceCaseGroupId = get(serviceCase, 'group.id');
  const programId = get(serviceCase, 'program.id');
  const program = get(state, 'groupsPrograms.data').find(({ id }) => id === programId);
  const paymentType = get(program, 'fee_schedule_program.attributes.payment_type');
  const isCostType = fspUtils.isCostPaymentType(paymentType);
  const serviceCases = get(state, `serviceCase.${contactId}`);
  const index = findIndex(serviceCases, (serviceCaseItem) => serviceCaseItem.id === serviceCase.id);

  const feeScheduleProvidedServices = get(state, `serviceCase.${contactId}[${index}].feeScheduleProvidedServices`);

  return {
    feeScheduleProvidedServices,
    paymentType,
    program,
    serviceCaseGroupId,
    isCostType,
  };
}

export default flow(
  FeatureFlagContainer,
  connect(mapStateToProps, {
  fetchGroupsPrograms,
  fetchProvidedServices,
}),
)(ServiceCaseFeeScheduleServicesProvided);
