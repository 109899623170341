import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compact, get, find, filter, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import cx from 'classnames';
import { FileThumbnail } from '@unite-us/client-utils';
import * as env from 'src/config/env/env.config';
import { hasPaysClaimsCodes, hasAmountProvided } from 'src/common/utils/FeatureFlags/flags';
import { Spinner } from 'src/common/spinners';
import { DollarAmount } from 'src/common/display/Money';
import { fetchFileUploads as fetchingFileUploads } from 'src/actions/FileUploads';
import { usePopulate } from 'src/api/APIHooks';
import MetafieldDetailViewCardData from 'src/components/Cases/components/Detail/MetafieldDetailViewCardData';
import './FeeScheduleProvidedServiceCardData.scss';

const CardDataItem = ({
  dataLabel,
  isHTML,
  label,
  required,
  value,
  amountField,
}) => (
  <div className="card-data__item">
    <div className="card-data__label-container col-sm-3">
      <span className={cx(
        'card-data__label font-heavy-font text-text-blue',
        required && 'ui-form-field__label--required',
      )}
      >
        {label}:
      </span>
    </div>
    <div className="col-sm-9">
      {amountField &&
        (
          <DollarAmount
            value={value}
            convertCents
            className={required ? 'text-red' : 'text-text-blue'}
            dataLabel={dataLabel}
          />
        )}
      {!amountField && isHTML && (
        // eslint-disable-next-line react/no-danger
        <span className="text-text-blue" data-test-element={dataLabel} dangerouslySetInnerHTML={{ __html: value }} />
      )}
      {!amountField && !isHTML && (
        <span className={required ? 'text-red' : 'text-text-blue'} data-test-element={dataLabel}>{value}</span>
      )}
    </div>
  </div>
);

CardDataItem.propTypes = {
  dataLabel: PropTypes.string,
  isHTML: PropTypes.bool,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.node.isRequired,
  amountField: PropTypes.bool,
};

CardDataItem.defaultProps = {
  dataLabel: '',
  isHTML: false,
  required: false,
  amountField: false,
};

const FeeScheduleProvidedServiceCardData = ({
  capInformation,
  className,
  metafields,
  providedService,
  paymentType,
  unit,
  fetchFileUploads,
  iconStyle,
  fileStatus,
  fileUploads,
  hasAmountProvidedFlag,
  showClaimsCodes,
}) => {
  const metaFileFields = filter(metafields, ['type', 'file']);
  const metaFileData = compact(metaFileFields.map((field) => find(providedService.metadata, ['field', field.field])));
  const metaFileObj = !isEmpty(metaFileData) ? compact(metaFileFields.map((field) => {
    const value = get(find(providedService.metadata, ['field', field.field]), 'value', []);
    // if metadata 'field' does not exist or metadata 'value' does not exist or is empty
    if (isEmpty(value)) {
      return null;
    }
    const files = compact(value.map((uuid) => find(fileUploads, ['id', uuid])));

    return {
      value: files,
      label: field.label,
    };
  })) : [];
  const metaFileMapping = {};
  metaFileObj.forEach((item) => {
    metaFileMapping[item.label] = true;
  });

  // Filter out file type metafields and metadata.
  // Prevents metadata section from rendering when only file metadata exists.
  const filteredMetafields = filter(metafields, (field) => field.type !== 'file');
  const filteredMetadata = filter(
    providedService.metadata,
    (field) => find(filteredMetafields, ['field', field.field]),
  );

  usePopulate('place_of_service', 'place_of_service', providedService, { queryConfig: { placeholderData: undefined } });
  const placeOfServiceCardData = providedService.place_of_service?.code ?
    [`${providedService.place_of_service?.code} - ${providedService.place_of_service?.description}`] : [];

  usePopulate('zcodes', 'zcodes', providedService, { queryConfig: { placeholderData: undefined } });
  const zcodeCardData = providedService?.zcodes?.map((zcode) => (`${zcode?.code} - ${zcode?.description}`));

  usePopulate('procedure_codes', 'procedure_codes', providedService, { queryConfig: { placeholderData: undefined } });
  const procedureCodeCardData = providedService.procedure_codes.map((pc) => (`${pc?.code} - ${pc?.description}`));

  useEffect(() => {
    if (!isEmpty(metaFileObj)) {
      fetchFileUploads({ recordId: providedService.id, recordType: 'provided_service' });
    }
  }, [fetchFileUploads, providedService.id]);

  return (
    <div className={cx(className, 'card-data')}>
      <div className="antialiased card-data__container space-y-2">
        {(paymentType === 'cost_based_reimbursement') &&
          (
            <>

              <CardDataItem
                dataLabel="unit-amount-value"
                label={hasAmountProvidedFlag ? 'Invoice Amount' : 'Cost'}
                required={!providedService.unit_amount}
                value={providedService.unit_amount ? providedService.unit_amount : 'Required'}
                amountField
              />
              {providedService.actual_unit_amount && hasAmountProvidedFlag && (
                <CardDataItem
                  dataLabel="actual-unit-amount-value"
                  label="Cost of Service"
                  value={providedService.actual_unit_amount}
                  amountField
                />
              )}
              <CardDataItem
                isHTML
                label="Cap"
                value={capInformation}
              />
            </>
          )}
        {(paymentType === 'funds_distributed') &&
          (
            <>
              <CardDataItem
                dataLabel="unit-amount-value"
                label="Amount Distributed"
                required={!providedService.unit_amount}
                value={providedService.unit_amount ? providedService.unit_amount : 'Required'}
                amountField
              />

              <CardDataItem
                dataLabel="payment-method-value"
                label="Distribution Method"
                value={providedService.payment_method}
              />
              <CardDataItem
                dataLabel="distribution-reason-value"
                label="Reason for Distribution"
                value={providedService.distribution_reason}
              />
              {providedService.distribution_id && (
                <CardDataItem
                  dataLabel="distribution-id-value"
                  label="Payment Distribution ID"
                  value={providedService.distribution_id}
                />
              )}
            </>
          )}

        {!((paymentType === 'cost_based_reimbursement') || (paymentType === 'funds_distributed')) && (
          <>

            <CardDataItem
              dataLabel="unit-amount-value"
              label={hasAmountProvidedFlag ? 'Invoice Amount' : 'Unit Amount'}
              required={!providedService.unit_amount}
              value={providedService.unit_amount || 'Required'}
            />

            {providedService.actual_unit_amount && hasAmountProvidedFlag && (
              <CardDataItem
                dataLabel="amount-provided-value"
                label="Amount Provided"
                value={providedService.actual_unit_amount}
              />
            )}
            <CardDataItem
              dataLabel="unit-value"
              label="Unit"
              required={!unit}
              value={unit || 'Required'}
            />
          </>
        )}
        {(paymentType === 'funds_distributed') && (
          <CardDataItem
            dataLabel="payee-select-value"
            label="Payee"
            value={providedService.payee}
          />
        )}
        <CardDataItem
          dataLabel="service-period-select-value"
          label="Period of Service"
          value={!providedService.ends_at ? 'Single Date' : 'Date Range'}
        />
        {
          showClaimsCodes && (
            <>
              {
                placeOfServiceCardData?.length > 0 && (
                  <CardDataItem
                    dataLabel="place-of-services-value"
                    label="Place of Service"
                    value={placeOfServiceCardData.join(', ')}
                  />
                )
              }
              {
                zcodeCardData?.length > 0 && (
                  <CardDataItem
                    dataLabel="zcode-values"
                    label="Zcodes"
                    value={zcodeCardData.join(', ')}
                  />
                )
              }
              {
                procedureCodeCardData?.length > 0 && (
                  <CardDataItem
                    dataLabel="procedure-code-values"
                    label="Procedure Codes"
                    value={procedureCodeCardData.join(', ')}
                  />
                )
              }
            </>
          )
        }
        {/* eslint-disable no-nested-ternary */}
        {
          providedService.starts_at ? (
            providedService.ends_at ? (
              <CardDataItem
                dataLabel="service-dates-value"
                label="Service Delivery Date(s)"
                value={(
                  <>
                    {moment(providedService.starts_at).utc().format('LL')}
                    &nbsp;-&nbsp;
                    {moment(providedService.ends_at).utc().format('LL')}
                  </>
                )}
              />
            ) : (
              <CardDataItem
                dataLabel="service-start-date-value"
                label="Service Delivery Date(s)"
                value={moment(providedService.starts_at).utc().format('LL')}
              />
            )
          ) : (
            <CardDataItem
              className="text-red"
              dataLabel="service-start-date-value"
              label="Service Delivery Date(s)"
              required
              value={'Required'}
            />
          )
        }
        {
          !isEmpty(filteredMetafields) && !isEmpty(filteredMetadata) && (
            <>
              <hr className="border-dark-fill-blue" />
              <div className="py-2">
                <MetafieldDetailViewCardData
                  metafields={filteredMetafields}
                  metadata={filteredMetadata}
                />
              </div>
            </>
          )
        }
        {providedService.notes && (
          <>
            <hr className="border-dark-fill-blue" />
            <CardDataItem
              dataLabel="service-note-value"
              label="Note"
              value={providedService.notes}
            />
          </>
        )}
        {!isEmpty(metaFileObj) && (
          <>
            <hr className="border-dark-fill-blue" />
            <div className="space-y-2">
              {metaFileObj.map((fileField) => (
                <div className="flex" key={fileField.label}>
                  <div className="pl-0 col-sm-3">
                    <span className="font-heavy-font text-text-blue">{fileField.label}:</span>
                  </div>
                  <div className="pl-0 col-sm-9">
                    {isEmpty(fileField.value) && fileStatus !== 'success' && <Spinner />}
                    {fileField.value.map((fileObject) => (
                      <div className="flex col-sm-12" key={fileObject.id}>
                        <div className="mr-2">
                          <FileThumbnail style={iconStyle} contentType={fileObject.content_type} />
                        </div>
                        <a
                          href={`${env.CORE_BASE_URL}${fileObject.path}`} rel="noreferrer" target="_blank"
                          className="text-action-blue"
                        >
                          {fileObject.filename}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {/* Add Empty file types now -- if it is required and it is not in metaFileObj */}
        {isEmpty(metaFileObj) && !isEmpty(metaFileFields) &&
          metaFileFields.filter((fileObject) => get(fileObject, 'validations.required')).length !== 0 && (
            <hr className="border-dark-fill-blue" />
          )}
        {metaFileFields.filter((fileObject) => get(fileObject, 'validations.required') &&
          !Object.keys(metaFileMapping).includes(fileObject.label))
          .map((fileObject) => (
            <div className="flex" key={fileObject.label}>
              <div className="pl-0 col-sm-3">
                <span className="font-heavy-font text-text-blue ui-form-field__label--required">
                  {fileObject.label}:
                </span>
              </div>
              <div className="pl-0 col-sm-9">
                <div className="flex col-sm-12">
                  <div className="mr-2">
                    <span className="text-red">Required</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
FeeScheduleProvidedServiceCardData.propTypes = {
  capInformation: PropTypes.string,
  className: PropTypes.string,
  fetchFileUploads: PropTypes.func.isRequired,
  fileStatus: PropTypes.string.isRequired,
  fileUploads: PropTypes.array,
  hasAmountProvidedFlag: PropTypes.bool,
  iconStyle: PropTypes.object,
  metafields: PropTypes.array,
  paymentType: PropTypes.string,
  providedService: PropTypes.object.isRequired,
  showClaimsCodes: PropTypes.bool,
  unit: PropTypes.string,
};

FeeScheduleProvidedServiceCardData.defaultProps = {
  capInformation: '',
  className: '',
  fileUploads: [],
  hasAmountProvidedFlag: false,
  iconStyle: {
    svg: {
      height: '18px',
      width: '14px',
      fill: '#4467AB',
    },
  },
  metafields: [],
  paymentType: '',
  showClaimsCodes: false,
  unit: '',
};

const mapStateToProps = (state) => {
  const fileUploads = get(state, 'fileUploads.fileUploads');
  const fileStatus = get(state, 'fileUploads.status');

  return {
    fileUploads,
    fileStatus,
    hasAmountProvidedFlag: hasAmountProvided(state),
    showClaimsCodes: hasPaysClaimsCodes(state),
  };
};

export default connect(mapStateToProps, {
  fetchFileUploads: fetchingFileUploads,
})(FeeScheduleProvidedServiceCardData);
