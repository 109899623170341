import { compact, get, uniqBy } from 'lodash';
import { stripHTML } from '@unite-us/ui';
import { dateToEpoch } from 'common/utils/utils';
import * as env from 'src/config/env/env.config';
import buildProcedureCodeInitialFormValues from './buildProcedureCodeInitialFormValues';

const getInitialFileValues =
  (
    metadata = [],
    metafields = [],
    fileUploads = [],
  ) => {
    const providedServiceFiles = {};

    metafields.forEach((field) => {
      if (field.type === 'file') {
      const typeOfFile = field.field;

      // Find all file ids attached to provided service
      const providedServiceFile = metadata.find((data) => data.field === typeOfFile);

      if (providedServiceFile) {
        const filesByFileType = [];
        // For every provided service file id find complete file data currently in state
        providedServiceFile.value.forEach((id) => {
          const fileData = get(fileUploads, id);

           if (fileData) {
            filesByFileType.push({
             file: {
              ...fileData,
              name: fileData.filename,
            },
             tempId: fileData.id,
             preview: `${env.CORE_BASE_URL}${fileData.path}`,
           });
          }
        });

        if (filesByFileType.length) providedServiceFiles[typeOfFile] = filesByFileType;
      }
    }
  });

    return providedServiceFiles;
  };

export function metadataInitialValues(metadata = [], metafields = [], fileUploads = []) {
  const existingMetadata = metadata ? metadata.map((data) => {
    const metafieldMatch = metafields.find((metafield) => metafield.field === data.field);
    switch (get(metafieldMatch, 'type')) {
      case 'address':
        return {
          ...metafieldMatch,
          address: {
            line_1: get(data, 'value.line_1', ''),
            line_2: get(data, 'value.line_2', ''),
            postal_code: get(data, 'value.postal_code', ''),
            city: get(data, 'value.city', ''),
            state: get(data, 'value.state', ''),
          },
        };
      case 'file': {
        const files = data.value ? data.value.map((uuid) => {
          const fileData = get(fileUploads, uuid);
          return fileData ? {
            id: fileData.id,
            name: fileData.filename,
            created_at: fileData.created_at,
            preview: `${env.CORE_BASE_URL}${fileData.path}`,
          } : null;
        }) : [];
        return { ...metafieldMatch, value: compact(files) };
      }
      case 'date':
      case 'datetime': {
        const date = data.value ? dateToEpoch(data.value) : data.value;
        return { ...metafieldMatch, value: date };
      }
      case 'dropdown': {
        const sanitizedValue = stripHTML(data.value);
        return { ...metafieldMatch, value: sanitizedValue };
      }
      default:
        return { ...metafieldMatch, value: data.value };
    }
  }) : [];
  const metadataFromMetafields = metafields.map(({ field }) => ({ field }));
  return uniqBy([...existingMetadata, ...metadataFromMetafields], (md) => md.field);
}

export function feeScheduleProvidedServiceInitialValues({
  providedService,
  capOrUnitInitialValue = '',
  metafields = [],
  feeScheduleProgram = {},
  fileUploads = [],
  insuranceExternalMemberId = '',
  isBillable = false,
}) {
  const initialProcedureCodeRowValues = buildProcedureCodeInitialFormValues({ providedService, feeScheduleProgram });

  if (providedService) {
    const serviceStartDate = get(providedService, 'starts_at');
    const serviceEndDate = get(providedService, 'ends_at');

    const initalFiles = getInitialFileValues(providedService.metadata, metafields, fileUploads);

    const initialValues = {
      files: initalFiles,
      provided_service: {
        form_id: providedService.id,
        id: providedService.id,
        unit_amount: providedService.unit_amount,
        unit: capOrUnitInitialValue,
        payee: providedService.payee,
        distribution_reason: stripHTML(get(providedService, 'distribution_reason')),
        payment_method: stripHTML(get(providedService, 'payment_method')),
        distribution_id: get(providedService, 'distribution_id'),
        note: get(providedService, 'notes'),
        metadata: metadataInitialValues(providedService.metadata, metafields, fileUploads),
        period_of_service: !serviceEndDate ? 'Single Date' : 'Date Range',
        service_start_date: serviceStartDate ? dateToEpoch(serviceStartDate) : '',
        service_end_date: serviceEndDate ? dateToEpoch(serviceEndDate) : '',
        place_of_service: get(providedService, 'place_of_service.id'),
        zcodes: get(providedService, 'zcodes', []).map((zcode) => zcode.id),
        procedure_code_rows: initialProcedureCodeRowValues,
        state: providedService.state,
      },
      insuranceExternalMemberId,
    };

    return initialValues;
  }
  return {
    provided_service: {
      period_of_service: 'Single Date',
      unit: capOrUnitInitialValue,
      metadata: metafields.map(({ field }) => ({ field })),
      state: isBillable ? 'submitted' : 'active',
      procedure_code_rows: initialProcedureCodeRowValues,
    },
    insuranceExternalMemberId,
  };
}

export default feeScheduleProvidedServiceInitialValues;
