/*
Expects a providedService with 0 or more procedure_codes and 0 or more modifiers
e.g.
{
  procedure_codes: [{id: <UUID>}, {id: <UUID>}],
  procedure_code_modifiers: [{id: <UUID>}, {id: <UUID>}, {id: <UUID>},],
}
Expects a feeScheduleProgram in the structure
e.g.
{
  force_procedure_code_associations: <boolean>,
  force_procedure_code_modifier_associations: <boolean>,
  procedure_codes: [{
    id: <UUID>,
    procedure_code_modifiers: [{id: <UUID>}, {id: <UUID>}, {id: <UUID>}]
  }],
}
Returns an array of Procedure Code Rows for populating existing values in forms
e.g.
[
  { procedure_code: { id: 1 }, procedure_code_modifiers: [{ id: 1 }, { id: 4 }] },
  { procedure_code: { id: 2 }, procedure_code_modifiers: [{ id: 2 }, { id: 3 }] },
]
*/
import { isEmpty } from 'lodash';

function buildProcedureCodeInitialFormValues({
  providedService = {},
  feeScheduleProgram = {},
}) {
  const forceProcedureCodes = feeScheduleProgram.force_procedure_code_associations ?? false;
  const forceModifiers = feeScheduleProgram.force_procedure_code_modifier_associations ?? false;
  const fspProcedureCodesEmpty = isEmpty(feeScheduleProgram.procedure_codes);
  const psProcedureCodesEmpty = isEmpty(providedService.procedure_codes);
  const returnEmptyRow = (forceProcedureCodes && forceModifiers) ||
    (forceProcedureCodes && fspProcedureCodesEmpty) ||
    (forceModifiers && psProcedureCodesEmpty) ||
    (!forceProcedureCodes && psProcedureCodesEmpty);
  const emptyRow = [{ procedure_code: '', procedure_code_modifiers: [] }];

  const filterModifiersByPcId = (modifiers, pcId) => (
    modifiers?.filter((modifier) => (modifier.procedure_code?.id === pcId))?.map((modifier) => (modifier.id)) ?? []
  );

  // Missing required info to generate rows
  if (returnEmptyRow) return emptyRow;

  // FSP is forcing only PC associations
  // return forced PCs from FSP with any matching ProvidedService PCMs
  if (forceProcedureCodes) {
    return feeScheduleProgram.procedure_codes.map((procedureCode) => ({
      procedure_code: procedureCode.id,
      procedure_code_modifiers: filterModifiersByPcId(providedService.procedure_code_modifiers, procedureCode.id),
    }));
  }

  // FSP is forcing only PCM associations
  // return ProvidedSerivce PCs with empty PCMs (PCMs are handled in core)
  // If there are no ProvidedService Procedure Code selections return an empty row
  if (forceModifiers) {
    return providedService.procedure_codes.map((procedureCode) => {
      const matchingFspPc = feeScheduleProgram.procedure_codes.find((pc) => (pc.id === procedureCode.id));
      const modifiers = matchingFspPc.procedure_code_modifiers ?? [];

      return { procedure_code: procedureCode.id, procedure_code_modifiers: modifiers.map((modifier) => (modifier.id)) };
    });
  }

  return providedService.procedure_codes.map((procedureCode) => ({
    procedure_code: procedureCode.id,
    procedure_code_modifiers: filterModifiersByPcId(providedService.procedure_code_modifiers, procedureCode.id),
  }));
}

export default buildProcedureCodeInitialFormValues;
